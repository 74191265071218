<template>
  <div id="add-exam">
    <v-card>
      <!-- HEADER TOOLBAR -->
      <v-toolbar class="page-primary-card-header">
        <v-list-item dark>
          <v-list-item-avatar color="primary lighten-2">
            <v-icon>{{card_header_props.header.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
            <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn
          @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
          icon
          dark
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-dialog v-model="card_header_props.helper.page_helper" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>{{$t("_common.How_can_I_use_this_form")}}</v-card-title>

            <v-card-text>{{$t("_create_exam._info.content")}}</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{$t("_common.OK")}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- HEADER TOOLBAR -->

      <v-container class="m-0 py-0 col-12">
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-card>
              <v-card-title>{{$t("_create_exam.Select_a_topic")}}</v-card-title>
              <v-card-subtitle>{{$t("_create_exam.Filters_the_questions")}}</v-card-subtitle>
              <v-sheet class="px-4">
                <v-text-field
                  v-model="search"
                  :label="this.$t('_create_exam.Search_Topic')"
                  flat
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>

              <v-card-text v-if="!showAddTopicButton" style="height: 500px; overflow: auto;">
                <v-treeview
                  :items="treeTopic.treeTopicData"
                  v-model="treeTopic.treeTopicDataModel"
                  :search="search"
                  selectable
                  hoverable
                  item-text="Title"
                  item-key="Id"
                  item-children="Children"
                  color="teal"
                  selection-type="all"
                  dense
                  :values = "selectedValues"
                  style="min-width: fit-content;"
                  @input="onSelectedTopic"
                />
              </v-card-text>
              <v-card-text v-if="showAddTopicButton">
                <v-dialog v-model="addTopicDialog" persistent max-width="80em">
                  <template v-slot:activator="{ on }">
                    <v-btn color="success" v-on="on">{{$t("_create_exam.Topic_not_found")}}</v-btn>
                  </template>
                  <add-topic></add-topic>
                  <v-spacer></v-spacer>
                  <v-container>
                    <v-btn color="blue" @click="onAddTopicDialogClose">{{$t("_common.Close")}}</v-btn>
                  </v-container>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-card>
              <v-card-title>{{$t("_create_exam.Please_select_questions")}}</v-card-title>
              <v-card-subtitle>{{$t("_create_exam.For_question_sheet")}}</v-card-subtitle>
              <v-sheet class="px-4">
                <v-text-field
                  v-model="searchWord"
                  :label='this.$t("_create_exam.Search_for_questions")'
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>

              <v-container>
                <v-data-table
                  :items="tableQuestionData"
                  v-model="selectedQuestions"
                  item-key="Id"
                  class="elevation-0"
                  disable-pagination
                  :headers="headers"
                  :search="searchWord"
                  show-select
                  hide-default-footer
                  height="475px"
                 :no-data-text="this.$t('_common.No_data_available')"
                >
                  <template v-slot:item.Text="{ item }">
                    <div v-html="item.Text" />
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card height="625">
              <v-form ref="examForm" v-model="valid" lazy-validation>
                <v-card-title>{{$t("_create_exam.Exam_name_and_Duration")}}</v-card-title>
                <v-card-subtitle>{{$t("_create_exam.You_can_preview_Text")}}</v-card-subtitle>
                <v-card-text>

                  <v-text-field
                    value="Example Exam Name"
                    v-model="examName"
                    :rules="validations.examNameRule"
                    :label='this.$t("_create_exam.Enter_your_exam_name")'
                  ></v-text-field>

                    <v-menu
                      v-model="activationDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="activationDate"
                          :rules="validations.activationDate"
                          :label="getLangText('_create_exam.Activation_Date')"
                          prepend-icon="event"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :reactive="false"
                        :full-width="false"
                        :show-current="true"
                        type="date"
                        :multiple="false"
                        :readonly="false"
                        :disabled="false"
                        v-model="activationDate"
                        @input="activationDateMenu = false"
                        :max="expirationDate"
                        :min="activationMinDate"
                      ></v-date-picker>
                    </v-menu>

                  <v-menu
                    ref="startTimeMenu"
                    v-model="startTimePicker"
                    :close-on-content-click="false"
                    :return-value.sync="startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startTime"
                        :rules="validations.activationStartTime"
                        :label="$t('_create_exam.StartTime')"
                        readonly
                        dense
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="access_time"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="startTimePicker"
                      v-model="startTime"
                      full-width
                      @click:minute="$refs.startTimeMenu.save(startTime)"
                      :min="minStartFirstTime"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>

                  <!-- :events="enableEvents ? functionEvents : null"
                dateFunctionEvents (date) {
                  const [,, day] = date.split('-')
                  if ([12, 17, 28].includes(parseInt(day, 10))) return true
                  if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
                  return false
                  },-->

                  <v-menu
                      v-model="expirationDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="expirationDate"
                          :label='getLangText("_create_exam.Expiration_Date")'
                          :rules="validations.activationExpirationDate"
                          prepend-icon="event"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :reactive="false"
                        :full-width="false"
                        :show-current="true"
                        type="date"
                        :multiple="false"
                        :readonly="false"
                        :disabled="false"
                        v-model="expirationDate"
                        @input="expirationDateMenu = false"
                        :min="activationDate"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="endTimeMenu"
                      v-model="endTimePicker"
                      :close-on-content-click="false"
                      :return-value.sync="endTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endTime"
                          :rules="validations.activationEndTime"

                          :label="$t('_create_exam.EndTime')"
                          readonly
                          dense
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="access_time"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="endTimePicker"
                        v-model="endTime"
                        full-width
                        @click:minute="$refs.endTimeMenu.save(endTime)"
                        :min="minStartTime"
                        format="24hr"
                      ></v-time-picker>
                    </v-menu>

                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="time"
                        :label='getLangText("_create_exam.Enter_Duration_for_exam")'
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="modal2" v-model="time" format="24hr" use-seconds>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">{{$t("_common.Cancel")}}</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(time)">{{$t("_common.OK")}}</v-btn>
                    </v-time-picker>
                  </v-dialog>
                  <v-text-field
                    type="number"
                     min="0" max="100"
                    value="Example Success Rate"
                    v-model="successRate"
                    :label='this.$t("_create_exam.Enter_Success_Rate")'
                    
                  ></v-text-field>
                  <v-checkbox v-model="randomQuestions" :label='getLangText("_create_exam.Randomize_questions")'></v-checkbox>
                  <div v-if="randomQuestions">
                    <p>{{$t('_create_exam.Select_question_amount')}}:</p>
                    <v-container>
                      <v-slider
                        v-model="randomQuestionAmount"
                        :rules="validations.randomQuestionAmountRule"
                        class="align-center"
                        :min="0"
                        :max="selectedQuestions.length"
                        thumb-label
                      ></v-slider>
                    </v-container>
                  </div>

                  <v-dialog v-model="activePrompt" max-width="600">
                    <v-card v-if="selectedQuestion != null">
                      <div class="question-text">
                        <div v-if="selectedQuestion.ImageUrl != null">
                            <v-img
                              :src="selectedQuestion.ImageUrl"
                            >
                            </v-img>
                          </div>
                        <blockquote class="blockquote"><div v-html="selectedQuestion.Text"/></blockquote>
                      </div>
                      
                      <v-card-text>
                        <div v-if="selectedQuestion.Type == $goc.ENUMS.QUESTION_TYPE.MultipleChoice || selectedQuestion.Type == $goc.ENUMS.QUESTION_TYPE.TrueFalse">
                          <div
                            v-for="(choice,c) in selectedQuestion.Choices"
                            :key="`question${selectedQuestion.Id}-choice${c}`"
                            class="exam-choice"
                          >
                            <div class="exam-choice-text">
                              <v-icon :color="getColor(choice)">{{getChoiceIcon(choice)}}</v-icon>
                              <span>{{ choice.Text }}</span>
                            </div>
                            <div class="exam-choice-image" v-if="selectedQuestion.Type == $goc.ENUMS.QUESTION_TYPE.MultipleChoice  && choice.ImageUrl != null">
                              <v-img
                                :src="choice.ImageUrl"
                              >
                              </v-img>
                            </div>
                          </div>
                        </div>
                        <v-list v-else-if="selectedQuestion.Type == $goc.ENUMS.QUESTION_TYPE.Matching">
                          <v-list-item
                            v-for="(mChoice,c) in selectedQuestion.MatchingChoices"
                            :key="`question${selectedQuestion.Id}-choice${c}`"
                          >
                            <v-list-item-content outlined>
                              <v-list-item-subtitle v-text="mChoice.Key" >
                              </v-list-item-subtitle> 
                              <div v-if="mChoice.ImageKeyUrl != null" style="display: flex; align-items: center; max-width: 30%; height: 50%; position: relative">
                                <v-img
                                  :src="mChoice.ImageKeyUrl"
                                >
                                </v-img>
                              </div>
                            </v-list-item-content>
                            <v-list-item-content >
                              <v-list-item-subtitle v-text="mChoice.Value">
                              </v-list-item-subtitle>
                              <div v-if="mChoice.ImageValueUrl != null" style="display: flex; align-items: center; max-width: 30%; height: 50%; position: relative">
                                <v-img
                                  :src="mChoice.ImageValueUrl"
                                >
                                </v-img>
                                </div>
                            </v-list-item-content>

                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="activePrompt = false">{{$t("_common.Close")}}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-text>
              </v-form>

            </v-card>
          </v-col>
        </v-row>
        <v-card-actions v-if="selectedQuestions.length > 0 || randomQuestions">
                <vs-row vs-justify="flex-end" xs12>
                <v-btn @click="clearForm" class="mr-2" v-on="on" dark color="warning">{{$t("_common.Reset")}}</v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="!updateMode"
                      dark
                      v-on="on"
                      depressed
                      color="success"
                      @click="createQuestionSheet"
                    >{{$t("_common.Create")}}</v-btn>
                    <v-btn v-else dark depressed color="success" @click="updateQuestionSheet">{{$t("_common.Update")}}</v-btn>
                  </template>
                  <span>{{$t("_create_exam.CreateAQuestionSheet")}}</span>
                </v-tooltip>
              </vs-row>
              </v-card-actions>
      </v-container>

      <v-container v-if="selectedQuestions.length > 0 || randomQuestions" class="mx-0 col-12">
        <v-layout>
          <v-card width="100%">
            <v-card-title v-if="!randomQuestions">{{$t("_create_exam.Exam_Preview")}} </v-card-title>
            <v-card-text v-if="!randomQuestions">
              <v-list width="100%">
                <v-list-item-group>
                  <v-list-item
                    :ripple="false"
                    :two-line="true"
                    :data="tq"
                    :key="indexq"
                    v-for="(tq, indexq) in selectedQuestions"
                  >
                    <v-list-item-avatar>
                      <v-chip outlined small color="grey" dark>{{indexq +1}}</v-chip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row>
                        <v-col class="col-3">{{tq.Topics[0] ? tq.Topics[0].Title : ''}}</v-col>
                        <v-col class="col-9"> <div v-html="tq.Text"/></v-col>
                      </v-row>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row>
                        <v-btn icon>
                          <v-icon
                            color="red lighten-1"
                            @click="deleteQuestionFromSheet(tq)"
                          >mdi-playlist-remove</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon color="grey lighten-1" @click="openDialog(tq)">mdi-information</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>

          </v-card>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { VSelectTree, VTree } from "vue-tree-halower";
import AddTopic from "./AddTopic.vue";
import AddQuestion from "./AddQuestion";

require("../../assets/css/neo.css");

export default {
  data() {
    return {
      selectedValues: [],
      activationDateMenu: false,
      expirationDateMenu: false,
      activationDate: new Date().toISOString().substr(0, 10),
      activationMinDate: new Date().toISOString().substr(0, 10),
      expirationDate: null,
      examCode: null,
      valid: true,
      validations: {
        examNameRule: this.$goc.validate.create().required(),
        activationStartTime: this.$goc.validate.create().required(),
        activationDate: this.$goc.validate.create().required(),
        activationExpirationDate: this.$goc.validate.create().required(),
        activationEndTime: this.$goc.validate.create().required(),
        randomQuestionAmountRule: this.$goc.validate
          .create()
          .required()
          .gt(2)
      },
      on: false,
      tableQuestionData: [],
      treeTopic: {
        treeTopicDataModel: [],
        treeTopicData: []
      },
      randomQuestionAmount: 1,
      activePrompt: false,
      randomQuestions: false,
      correctChoiceHTML: "",
      selected: [],
      selectedQuestions: [],
      searchWord: "",
      examName: "",
      successRate: 0,
      time: "00:10:00",
      modal2: false,
      addTopicDialog: false,
      addQuestionsDialog: false,
      headers: [
        {
          text:this.$t("_create_exam.Question_Text"),
          align: "left",
          sortable: false,
          value: "Text"
        },
        { text: this.$t("_create_exam.Point"), value: "Point" }
      ],
      selectedQuestion: null,
      found: false,
      updateMode: false,
      settings: {
        maxScrollbarLength: 30,
        wheelSpeed: 0.2
      },
      search: null,
      card_header_props: {
        header: {
          headLine: this.$t("_verticalNavbar.CreateExam"),
          subTitle: "",
          icon: "edit"
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false
        }
      },
      startTimePicker: false,
      startTime: null,
      endTimePicker : false,
      endTime: null,
    };
  },
  watch: {
     selectedQuestions: {
      handler(val) {
        this.validations.randomQuestionAmountRule = this.$goc.validate
          .create()
          .required()
          .gt(2)
          .lt(val.length - 1);
      },
    },
    activationDate(){
      this.startTime = null;
    },
    expirationDate(){
      this.endTime = null;
    },
  },
  methods: {
    getLangText(_text) {
      return this.$goc.langHelper.getLangText(_text);
    },
    reloadQuestionList() {
      this.getQuestions();
    },
    init() {
      let param = this.$route.params.sheetId;
      this.$goc.console.log(param);
      if (!isNaN(param) && typeof param !== "undefined") {
        this.populatePageBySheet(param);
      }
    },
    clearForm() {
      this.$refs.examForm.resetValidation();
      this.selected = [];
      this.selectedQuestions = [];
      this.time = "00:10:00";
      this.examName = "";
      this.successRate= 0;
      this.randomQuestions = false;
      this.randomQuestionAmount = 0;
    },
    getTopics() {
      this.$goc.request.get(this.$enums.API.Topic, response => {
        // this.$goc.console.log(response);
        this.treeTopic.treeTopicData = response.Result.Topics;

        // setTimeout(()=>{
        //   this.treeTopic.treeTopicDataModel = [5];
        // }, 300);

        if (this.treeTopic.treeTopicData.length > 0) {
          this.$goc.notify.info({
            title: this.$t("_create_exam.Information"),
            message: this.$t("_create_exam.Select_at_least_one_topic_to_load_questions")
          });
        }
      });
    },
    getQuestions(searchWord) {
      const topics = this.selected;
      let query = "?";
      if (topics && topics.length > 0) {
        this.$goc.console.log(topics);
        query = query + `topic=${topics}&`;
        query = query + `search=${searchWord || ""}`;
        this.$goc.console.log(query);

        this.$goc.request.get(this.$enums.API.Question + query, response => {
          this.$goc.console.log(response);
          if (response.IsSuccess) {
            this.tableQuestionData = response.Result.Questions;
            console.log(this.tableQuestionData);
          } else {
            this.tableQuestionData = [];
          }
        });
      } else {
        this.tableQuestionData = [];
        this.$goc.notify.info({
          title: this.$t("_create_exam.Information"),
          message: this.$t("_create_exam.Select_at_least_one_topic_to_load_questions")
        });
      }
    },
    populatePageBySheet(sheetId) {
      const _self = this;

      this.$goc.request.get(
        this.$enums.API.Question + `${sheetId}`,
        response => {
          this.$goc.console.log(response);
          _self.sheetId = response.Result.Sheet.Id;
          _self.selectedQuestions = response.Result.Questions;
          _self.time = this.fancyTime(parseInt(response.Result.Sheet.Time));
          _self.examName = response.Result.Sheet.Title;
          _self.activationDate = response.Result.Sheet.ActivationDate;
          _self.expirationDate = response.Result.Sheet.ExpirationDate;
          _self.successRate = response.Result.Sheet.SuccessRate;
          _self.updateMode = true;
        }
      );
    },
    toSeconds(time) {
      var hms = time;
      var a = hms.split(":");
      return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    },
    createQuestionSheet() {
      if (this.validate()) {
        if (this.time != null) {
          var seconds = this.toSeconds(this.time);
          if (seconds > 0) {
            var questionList = [];
            this.selectedQuestions.forEach(element => {
              questionList.push(element.Id);
            });
            let sDate = this.startDateTime;
            let eDate = this.endDateTime;
            let activationDate = new Date(sDate);
            let activationDateSeconds = activationDate.getTime() / 1000;
            let expirationDate = new Date(eDate);
            let expirationDateSeconds = expirationDate.getTime() / 1000;

            if (this.randomQuestions) {
              this.$goc.request.post(
                this.$enums.API.QuestionSheet,
                {
                  Name: this.examName,
                  Duration: { Seconds: seconds, Nanos: 0 },
                  Randomized: true,
                  Topics: this.selected,
                  ActivationDate: {Seconds: activationDateSeconds, Nanos: 0},
                  ExpirationDate: {Seconds: expirationDateSeconds, Nanos: 0},
                  QuestionAmount: this.randomQuestionAmount,
                  Questions: questionList,
                  SuccessRate: this.successRate,
                },
                () => {
                  this.$goc.notify.success({ title: this.$t('_common.Success'), message: this.$t("_create_exam.Created_sheet")});
                  this.clearForm();
                }
              );
            } else {
              this.$goc.request.post(
                this.$enums.API.QuestionSheet,
                {
                  Name: this.examName,
                  Questions: questionList,
                  Duration: { Seconds: seconds, Nanos: 0 },
                  ActivationDate: {Seconds: activationDateSeconds, Nanos: 0},
                  ExpirationDate: {Seconds: expirationDateSeconds, Nanos: 0},
                  SuccessRate: this.successRate,
                },
                response => {
                  this.$goc.console.log(response);
                  this.$goc.notify.success({title: this.$t('_common.Success'), message: this.$t("_create_exam.Created_sheet") });
                  this.clearForm();
                }
              );
            }
          } else {
            this.$goc.notify.warning({
              message: this.$t("_create_exam.Duration_must_be_greater_than_zero")
            });
          }
        } else {
          this.$goc.notify.warning({ message: this.$t("_create_exam.Please_check_your_duration")});
        }
      }
    },
    validate() {
      return !!this.$refs.examForm.validate();
    },
    updateQuestionSheet() {
      if (this.validate()) {
        let questionList = [];
        this.selectedQuestions.forEach(element => {
          questionList.push(element.Id);
        });

        var hms = this.time;
        var a = hms.split(":");
        var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        let payload = {
          Name: this.examName,
          Questions: questionList,
          Duration: {
            Seconds: seconds,
            Nanos: 0
          },
          SuccessRate: this.successRate
        };
        this.$goc.request.put(
          this.$enums.API.QuestionSheet,
          payload,
          response => {
            this.$goc.console.log(response);
          }
        );
      }
    },
    choiceIcon(item) {
      let choiceChar = String.fromCharCode("1".charCodeAt(0) + item);
      return `mdi-numeric-${choiceChar}-circle-outline`;
    },
    nodeSelectedEvent() {
      var topics = this.selected;
      if (topics.length > 0) {
        this.searchQuestions();
      } else {
        this.tableQuestionData = [];
      }
    },
    onSelectedTopic(item) {
      if (item && item.length > 0) this.selected = item;
      else {
        this.selected = null;
        this.tableQuestionData = [];
      }
      this.getQuestions(this.searchWord);
    },
    dialogNotify(title, message, notifyColor) {
      this.$vs.notify({
        title: title,
        text: message,
        color: notifyColor
      });
    },
    getChoiceIcon(choice) {
      if (choice.Correct) {
        return "mdi-checkbox-blank-circle";
      } else return "mdi-checkbox-blank-circle-outline";
    },
    searchQuestions() {
      var topics = this.selected;
      if (topics !== []) {
        topics.forEach(element => {
          delete element["Title"];
          delete element["Selected"];
        });
      }
      this.getQuestions(this.searchWord);
    },
    deleteQuestionFromSheet(item) {
      var index = 0;
      this.selectedQuestions.forEach(element => {
        if (element.Id === item.Id) {
          index = this.selectedQuestions.indexOf(item);
          this.selectedQuestions.splice(index, 1);
        }
      });
    },
    choiceColor(item) {
      let chosen = item.AnswerChoice;
      for (var i = 0; i < item.Question.Choices.length; i++) {
        if (item.Question.Choices[i].Id === chosen) {
          if (item.Question.Choices[i].Correct) return "green";
          else return "red";
        }
      }
    },
    getColor(choice) {
      if (choice.Correct) {
        return "green";
      } else return "gray";
    },
    fancyTime(time) {
      let hrs = ~~(time / 3600);
      let mins = ~~((time % 3600) / 60);
      let secs = ~~time % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      let ret = "";

      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
    },
    openDialog(question) {
      const _self = this;
      _self.activePrompt = true;
      _self.selectedQuestion = question;
      this.$goc.console.log(question);
    },
    onAddTopicDialogClose() {
      this.addTopicDialog = false;
      this.getTopics();
    },
    onAddQuestionDialogClose() {
      this.addQuestionsDialog = false;
      this.reloadQuestionList();
    }
  },
  components: {
    VTree,
    VSelectTree,
    AddTopic,
    AddQuestion
  },
  mounted: function() {
    this.examCode = this.$moment().format("YYYYMMDDHHmm");
    this.getTopics();
    this.init();
  },
  computed: {
    showAddTopicButton() {
      return (
        this.treeTopic.treeTopicData == null ||
        this.treeTopic.treeTopicData.length < 1
      );
    },
    startDateTime() {
      const startDate = this.$moment(`${this.activationDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm');
      return startDate.format();
    },
    endDateTime() {
      const endDate = this.$moment(`${this.expirationDate} ${this.endTime}`, 'YYYY-MM-DD HH:mm');
      return endDate.format();
    },
    minStartFirstTime() {
      if(new Date(this.activationDate).getDate() == new Date().getDate())
          return this.$moment((new Date())).format('HH:mm');
    },
    minStartTime() {
      if(new Date(this.activationDate).getDate() == new Date(this.expirationDate).getDate()){
        const start = this.$moment(this.startDate);
        const end = this.$moment(this.endDate);

        return end > start ? null : this.startTime;
      }
    },
  }
};
</script>

<style lang="scss">
.question-text {
  margin: 1rem;
  .v-image {
    margin: 0 auto;
    width: 50%;
  }

  .blockquote {
    border-radius: 20px;
    background-color: rgb(226, 226, 226);
  }
}

.exam-choice {
    border: 1px solid silver;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;

  .exam-choice-text {
    display: flex;

    i {
      margin-right: 1rem;
    }
  }

  .exam-choice-image {
    width: 40%;
    margin: 0 auto;
  }
}

.con-expand-users {
  .con-btns-user {
    display: flex;
    padding: 10px;
    padding-bottom: 0;
    align-items: center;
    justify-content: space-between;

    .cardx, .con-userx {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .list-icon {
    i {
      font-size: 0.9rem !important;
    }
  }
}
</style>
